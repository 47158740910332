/**
 * add/removes black background behind navbar
 */
window.addEventListener("scroll", ()=>{
    if (window.scrollY>=70) {
        document.querySelector(".site-navigation,.trans-navigation").classList.add("header-white");
    } else {
        document.querySelector(".site-navigation,.trans-navigation").classList.remove("header-white");
    }
});




document.addEventListener('DOMContentLoaded', function() {
  // Get current page URL
  const currentUrl = window.location.href;

  // Get all navbar links
  const navLinks = document.querySelectorAll(".nav-link");

  navLinks.forEach(function(link) {
      const linkUrl = link.getAttribute('href');

      if (linkUrl) {  // Ensure linkUrl is not null or undefined
          if (currentUrl.endsWith(linkUrl)) {
              // Hide the parent .nav-item
              link.parentElement.style.display = 'none';  // Use the "!" to assert that parentElement is non-null
          }
      }
  });
});