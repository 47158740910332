// initialize firebase client and export app

import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyAnGyKogZaFgI9j7SQPEM7fYNnjQmrloJg",
    authDomain: "ansync-website-ee7be.firebaseapp.com",
    projectId: "ansync-website-ee7be",
    storageBucket: "ansync-website-ee7be.appspot.com",
    messagingSenderId: "1037315684060",
    appId: "1:1037315684060:web:fb384243c7fef54779a619",
    measurementId: "G-H8NB95LXGK",
};

export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
