import { httpsCallable } from "firebase/functions";
import { functions } from "../lib/scripts/app";

declare function gtag(command: string, target: string, event?: any): void;

// import { functions } from '../scripts/app';
// import { contactRequest } from '../../../functions/src/email/interfaces';

/**
 * generic request for an email to both Ansync and the customer
 */
export interface contactRequest {
    /**
     * what ansync service is being requested
     */
    serviceType: string,

    /**
     * email of the customer
     */
    customerEmail: string,

    /**
     * The message of the content that was sent by the customer
     */
    content: string,

    /**
     * Customer name
     */
    name: string,

    /**
     * customer phone number
     */
    phone: string

}
/**
 * This is the component that asks for the name, subject, email address, phone number, and message. Used in generic contacts for Ansync Labs
 */
export class AnsyncContactForm extends HTMLElement {
    public _initialized = false; // whether the component has been initialized or not

    // Props
    public _serviceName = ""; // name of the service to contact, this is based on the backend emailConsts inside of email.ts
    public _ansyncEmail = ""; // the email to send the contact email on the Ansync end to
    // public _templateIdToCustomer: string = ""; // the templated id to send the sendgrid email to the customer

    // firebase function
    public customEmailCallable = httpsCallable(functions, "handleContactEmail"); // firebase function to send emails based on the two templates inputted in: customer response and ansync

    public loadingId = "loading"; // id of the element that is "loading" for email feedback

    /**
     * Constructor
     */
    constructor() {
        super();
        this.innerHTML = `
    <section class="" id="contact">
        <div class="container">
            <div class="row mb-4">
                <div class="">
                    <!-- Heading -->
                    <h2 class="section-title mb-2 text-white">
                        Contact Us:
                    </h2>
                </div>
            </div> <!-- / .row -->

            <div class="row">
        <div class="col-lg-6">
            <!-- form message -->
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-success contact__msg" style="display: none" role="alert">
                        Your message was sent successfully.
                    </div>
                </div>
            </div>
            <!-- end message -->
           
            <!-- Contacts Form -->
            <form class="contact_form">
                <div class="row">
                    <!-- Input -->
                    <div class="col-sm-6 mb-6">
                        <div class="form-group">
                            <label class="text-white h6 small d-block text-uppercase">
                                Your name
                                <span class="text-white text-danger">*</span>
                            </label>

                            <div class="input-group">
                                <input class="form-control" id="contact-form-name" name="name" id="name" required="" placeholder=""
                                    type="text">
                            </div>
                        </div>
                    </div>
                    <!-- End Input -->


                    <div class="w-100"></div>

                    <!-- Input -->
                    <div class="col-sm-6 mb-6">
                        <div class="form-group">
                            <label class="text-white h6 small d-block text-uppercase">
                                Your email address
                                <span class="text-white text-danger">*</span>
                            </label>

                            <div class="input-group ">
                                <input class="form-control" name="email" id="contact-form-email" required=""
                                    placeholder="" type="email">
                            </div>
                        </div>
                    </div>
                    <!-- End Input -->

                    <!-- Input -->
                    <div class="col-sm-6 mb-6">
                        <div class="form-group">
                            <label class="text-white h6 small d-block text-uppercase">
                                Your Phone Number
                                <span class="text-white text-danger">*</span>
                            </label>

                            <div class="input-group ">
                                <input class="form-control" id="contact-form-phone" name="phone" required=""
                                    placeholder="" type="text">
                            </div>
                        </div>
                    </div>
                    <!-- End Input -->
                </div>

                <!-- Input -->
                <div class="form-group mb-5">
                    <label class="text-white h6 small d-block text-uppercase">
                        Message
                        <span class="text-white text-danger">*</span>
                    </label>

                    <div class="input-group">
                        <textarea class="form-control" rows="4" name="message" id="contact-form-message" required=""
                            placeholder=""></textarea>
                    </div>
                </div>
                <!-- End Input -->

                <div class="">
                    <input name="submit" type="submit" class="btn btn-primary btn-circled" value="Send Message" id="send-contact-email">

                    <p class="small pt-3">Or visit us in person: <br>5090 Robert J. Mathews Parkway, El Dorado Hills California</br></p>
                </div>
            </form>
            <!-- End Contacts Form -->
        </div>
    </div>
        </div>
    </section>
        `;
    }

    /**
     * Called when placed in dom
     * @returns {void}
     */
    async connectedCallback() {
        // prevent duplicate call to this initialized function
        if (this._initialized) return;
        this._initialized = true;
        // add in button functionalities, use event listener
        const formElement = this.querySelector("form");

        this.querySelector("#send-contact-email")?.addEventListener("click", (e) => {
            e.preventDefault();
            const valid = formElement?.reportValidity();
            if (valid) {
                this.createContactEmail();
            }
        });
    }

    //     <contact-form data-serviceName="Ansync XRay" data-templateIdToAnsync="123" data-ansyncEmail="xray@ansync.com" data-templateIdToCustomer="456"> </contact-form>

    /**
     * This will allow the component to be use dynamically, allowing user to
     * directly add new links to the component
     * @returns { string[] }
     */
    static get observedAttributes() { // like a use effect with dependency
        return [ "data-service-name", "data-template-id-to-ansync", "data-ansync-email", "data-template-id-to-customer" ]; // custom attributes
    }

    /**
     * Called when attribute changes
     * @param name Name of attribute
     * @param oldValue Old Value
     * @param newValue New Value
     */
    async attributeChangedCallback(name: string, oldValue: string, newValue: string) { // callback function from useEffect
        switch (name) {
        default:
            break;
        }
    }

    /**
     * Button trigger function to extract values from the form and call the firestore function
     */
    createContactEmail() {
        // check to see if the serviceName and ansyncEmail datasets are present
        if (!this.dataset.serviceName) {
            console.error("Missing serviceName");
            throw new Error("Missing serviceName");
        }

        // get information from the page
        const nameElem = this.querySelector("#contact-form-name"); // use query selector so that if there are multiple forms, it'll only get this one
        const emailElem = this.querySelector("#contact-form-email");
        const messageElem = this.querySelector("#contact-form-message");
        const phoneElem = this.querySelector("#contact-form-phone");

        // check if elements were gotten
        if (!nameElem || !emailElem || !messageElem || !phoneElem) {
            console.error("One of the custom xray email elements was not found");
            console.error("Name:", nameElem);
            console.error("Email:", emailElem);
            console.error("Message:", messageElem);
            throw new Error("One of the comtact email elements was not found");
        }

        // get values
        const name = (nameElem as HTMLInputElement).value;
        const email = (emailElem as HTMLInputElement).value;
        const message = (messageElem as HTMLTextAreaElement).value;
        const phone = (phoneElem as HTMLInputElement).value;
        // check for values

        // input validation is handled by the form
        const contactButtonElem = this.querySelector("#send-contact-email") as HTMLButtonElement;
        this.loading(contactButtonElem);


        // create object and call cloud function
        const payload: contactRequest = {
            serviceType: this.dataset.serviceName,
            customerEmail: email,
            content: message,
            phone: phone,
            name: name,
        };
        // console.log("Sending the following payload to firebase:", payload);

        // call the cloud function
        this.customEmailCallable(payload).then((res) => {
            console.log("Email has been sent with response");
            console.log(res);
            this.positiveFeedback();
        }).catch((error) => {
            console.error("Error has occurred with httpsCallable");
            console.error(error);
            this.negativeFeedback();
        });
    }

    // scripts pertaining to user feedback, such as loading, successful emails, failed emails, etc

    /**
     * Show a successful email sending
     */
    positiveFeedback() {
        // assume that we already have a loading id
        const loadingElem = this.querySelector(`#${this.loadingId}`);
        const newDiv = document.createElement("div");
        loadingElem!.replaceWith(newDiv);
        newDiv.innerText = "Successfully sent email";
        newDiv.style.color = "green";
        gtag("event", "conversion", {
            "send_to": "AW-11027734159/8GLACKr4suUYEI-9t4op",
        });
    }

    /**
     * Show the failed status of the email
     */
    negativeFeedback() {
        const loadingElem = this.querySelector(`#${this.loadingId}`);
        const newDiv = document.createElement("div");
        loadingElem!.replaceWith(newDiv);
        newDiv.innerText = "An error has occurred while sending the email.";
        newDiv.style.color = "red";
    }

    /**
     * Replace a button with a loading text or something else
     * @param buttonElem Element to replace with text
     */
    loading(buttonElem: HTMLButtonElement) {
        const newDiv = document.createElement("div");
        buttonElem.replaceWith(newDiv);
        newDiv.innerText = "Sending email... please wait...";
        newDiv.id = this.loadingId;
        newDiv.style.backgroundColor = "white";
    }
}

window.customElements.define("contact-form", AnsyncContactForm);
